.swiper {
  position: relative;
}

/* Prevent text selection */
.swiper-slide *::selection {
  background: none;
  color: inherit;
}

.swiper-slide {
  padding: 0;
}

.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: unset;
  bottom: 270px;
  width: 30px;
  height: 30px;
  margin: 0;
  padding: 5px;
  align-items: center;
  background-color: rgba(63, 93, 220, 0.8);
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgPHBhdGggZD0ibTcuMzIyIDE1LjQyIDcuMjEtNy4yMUw3LjMyMyAxTTEyLjg4NyA4LjQwOUgxIiBzdHJva2U9IiNmZmYiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=")
      center no-repeat,
    rgba(63, 93, 220, 0.8);
  border-radius: 50%;
}

.swiper-button-prev {
  rotate: 180deg;
}
.swiper-button-disabled {
  background-color: rgba(63, 93, 220, 0.35);
}

.swiper-button-prev {
  left: 8px;
}

.swiper-button-next {
  right: 8px;
}

/* Icon size */
.swiper-button-prev::after,
.swiper-button-next::after {
  content: "";
  font-size: 16px;
  color: #fff;
}
