.formGroup .labelContainer {
  position: relative;
}
.formGroup .labelContainer label {
  position: absolute;
  top: 10px;
  left: 8px;
  padding: 0 8px;
  user-select: none;
  pointer-events: none;
  z-index: 2;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}
.formGroup .labelContainer label.focused {
  background-color: #161618;
  top: -8px;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  font-size: 10px;
}
