.ReactModal__Body--open {
  overflow: hidden;
}

.ReactModal__Overlay {
  opacity: 0;
  will-change: opacity;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}
