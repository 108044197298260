.swiper {
  position: relative;
}

/* Prevent text selection */
.swiper-slide *::selection {
  background: none;
  color: inherit;
}

.swiper-slide {
  padding: 0 36px;
}

.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 0;
  width: 36px;
  height: 30px;
  margin: 0;
  padding-top: 5px;
  align-items: flex-start;
}

.swiper-button-prev {
  left: 0;
}

.swiper-button-next {
  right: 0;
}

/* Icon size */
.swiper-button-prev::after,
.swiper-button-next::after {
  font-size: 20px;
}
