.ReactModal__Body--open {
  overflow: hidden;
}

.ReactModal__Content.slide {
  bottom: -100%;
}

.ReactModal__Content--after-open.slide {
  bottom: 0;
}

.ReactModal__Content--before-close.slide {
  bottom: -100%;
}
